import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CallLogsList from './CallLogsList'

export default function CallLogs() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/call_logs" component={CallLogsList} />
                
                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}