import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'

export default function TutorialsModal({ visible, onClose }) {
    return (
        <Modal isOpen={visible} toggle={onClose} size="xl">
            <ModalHeader toggle={onClose}>Tutoriales</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <h5 className='mb-10 bold text-primary text-center'>
                            ¿Cómo visualizar las campañas vigentes?
                        </h5>
                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/lPiwIRXzs_g" title="Recompra Fiel Momentos - Campañas Vigentes" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </Col>
                    <Col>
                        <h5 className='mb-10 bold text-primary text-center'>
                            ¿Cómo gestionar llamadas de seguimiento?
                        </h5>
                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/BuLq428pxSw" title="Recompra Fiel Momentos - Como gestionar llamadas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5 className='mb-10 bold text-primary text-center'>
                            ¿Cómo registrar una recompra nuevo?
                        </h5>
                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/Q2tq2X8HAx8" title="Recompra Fiel Momentos - Como Registrar Una Recompra" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </Col>
                    <Col>
                        <h5 className='mb-10 bold text-primary text-center'>
                            ¿Cómo utilizar los filtros avanzados?
                        </h5>
                        <iframe width="100%" height="300" src="https://www.youtube.com/embed/CggQ6hiiBaA" title="Recompra Fiel Momentos - Cómo utilizar los filtros avanzados" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}