import React from 'react'
import { Drawer } from 'antd'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

export default function CustomFiltersDrawer({ 
	visible, onClose, filters, setFilters, reload 
}) {
	const categories = [
		'ALMOHADAS',
		'ACCESORIOS',
		'BASES Y PATAS',
		'CAMAS',
		'COLCHONES',
		'COMBO',
		'LENCERIA',
		'MUEBLES',
		'SILLAS',
	]

	const gamasColchones = [
		'BASICA',
		'MEDIA',
		'ALTA',
		'PREMIUM',
		'HOTELERA'
	]

	const handleCategoriesChange = (e, term) => {
		setFilters({ 
			...filters,
			'filter[products.category]': 
				e.target.checked 
					? `${term},${filters['filter[products.category]']}`
					: filters['filter[products.category]'].replace(`${term},`,'')
		})
	}

	const handleGamasColchonesChange = (e, term) => {
		setFilters({ 
			...filters,
			'filter[products.family]': 
				e.target.checked 
					? `${term},${filters['filter[products.family]']}`
					: filters['filter[products.family]'].replace(`${term},`,'')
		})
	}

	const handleFilterChange = (filter, term) => {
		setFilters({ 
			...filters,
			[`filter[${filter}]`]: term
		})
	}
	
	return (
		<React.Fragment>
			<Drawer 
				visible={visible}
				onClose={onClose}
				title="Filtros Avanzados"
				width="350px"
			>
				<Form.Group>
					<Form.Label>Categorias compradas por el cliente</Form.Label>
					{ categories.map(category => (
						<Form.Check 
							key={category}
							type="checkbox"
							label={category}
							checked={filters['filter[products.category]'].includes(category) ? true : false}
							onChange={e => handleCategoriesChange(e, category)}
						/>
					)) }
				</Form.Group>
				<Form.Group>
					<Form.Label>Gamas de Colchones (Solo para colchones)</Form.Label>
					{ gamasColchones.map(gama => (
						<Form.Check 
							key={gama}
							type="checkbox"
							label={gama}
							checked={filters['filter[products.family]'].includes(gama) ? true : false}
							onChange={e => handleGamasColchonesChange(e, gama)}
						/>
					)) }
				</Form.Group>
				<Form.Group>
					<Form.Label>Facturas Desde</Form.Label>
					<Form.Control 
						type="date"
						value={filters['filter[sold_at_from]']}
						onChange={e => handleFilterChange('sold_at_from', e.target.value)}
						max={filters['filter[sold_at_until]']}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Facturas Hasta</Form.Label>
					<Form.Control 
						type="date"
						value={filters['filter[sold_at_until]']}
						onChange={e => handleFilterChange('sold_at_until', e.target.value)}
						min={filters['filter[sold_at_from]']}
					/>
				</Form.Group>
				<Button 
					color="primary" 
					onClick={() => {
						reload()
						onClose()
					}}
					className="mt-2"
				>
					Aplicar Filtros
				</Button>
			</Drawer>
		</React.Fragment>
	)
}