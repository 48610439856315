import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'

export default function CallLogsTable({ callLogs, reload, pagination, setPagination }) {
	
	const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
		{
			title: 'Fecha Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MM-YYYY hh:mm A')
		},
		{
			title: 'Factura Asociada',
			dataIndex: 'invoice',
			render: t => t ? t.reference.replace(/(\w| )(?=(\w| ){4})/g, '*') : <em className='small text-muted'>Sin factura asociada</em>
		},
		{
			title: 'Cédula Cliente',
			dataIndex: 'client_doc',
		},
		{
			title: 'Resultado',
			dataIndex: 'outcome'
		},
		{
			title: 'Comentarios',
			dataIndex: 'comment',
            responsive: ['md'],
		}
	]

	const expandedRowRender = record => (
		<React.Fragment>
			<p className='mb-0'><strong>Comentarios del asesor: </strong>{record.comment}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={callLogs}
				columns={columns}
				rowKey={r => r.id}
				loading={!callLogs}
				pagination={false}
				size="small"
				expandable={{ expandedRowRender }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}