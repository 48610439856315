import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { logout } from '../../containers/Authentication/services'
import { userLogout } from '../../containers/Authentication/actions'
import { handleError } from '../../helpers'
import TutorialsModal from './TutorialsModal'

export default function MainMenu({ closeMenu }) {
	const user = useSelector(state => state.auth.user)
	const [showTutorialsModal, setShowTutorialsModal] = useState(false)
	const dispatch = useDispatch()

	const onLogout = () => {
		logout()
			.then(() => dispatch(userLogout()))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Container>
				<UserContainer>
					<UserIcon>
						<FontAwesomeIcon icon={faUser} />
					</UserIcon>
					<UserName>{user.name}</UserName>
					<p>c.c. {user.document}</p>
					<p className="mb-0 text-link" onClick={onLogout}>Cerrar Sesión</p>
				</UserContainer>
				<MenuList>				
					{/* <li><Link to="/customers" onClick={closeMenu}>Clientes de la Tienda</Link></li>  */}
					<li><Link to="/invoices" onClick={closeMenu}>Facturas de la Tienda</Link></li> 
					<li><Link to="/call_logs" onClick={closeMenu}>Llamadas de Seguimiento</Link></li> 
					<li><Link to="/campaigns" onClick={closeMenu}>Campañas Vigentes</Link></li> 
					{/* <li><Link to="/segments" onClick={closeMenu}>Segmentos Generales</Link></li> */}
					<li><Link to="/sale_logs" onClick={closeMenu}>Recompras Registradas</Link></li>
					{ user.role === 'admin' && (
						<li><Link to="/reports/general" onClick={closeMenu}>Reporte de Resultados</Link></li>
					)}
				</MenuList>
				<Link to="/invoices" onClick={closeMenu}>
					<Button color="primary" className="mb-3">Ver Facturas</Button>
				</Link>
				<p>Correo de soporte: soporte@smart4.com.co</p>
				<p className='text-link mt-10' onClick={() => setShowTutorialsModal(true)}>
					Ver Videos Tutoriales
				</p>
			</Container>
			{ showTutorialsModal && (
				<TutorialsModal
					visible
					onClose={() => setShowTutorialsModal(false)}
				/>
			)}
		</React.Fragment>
	)
}

const Container = styled.div`
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	z-index: 9;	
	padding-top: 50px;
	width: 240px;
	background-color: white;
	text-align: center;
	-webkit-box-shadow: 3px 0 12px 0 #b3b3b3;
	box-shadow: 3px 0 12px 0 #b3b3b3;
	overflow-y: scroll;
`

const UserContainer = styled.div`
	padding: 40px 20px 30px;
	background-color: #e8e8e8;
`

const UserName = styled.h6`
	font-size: 17px;
	text-transform: uppercase;
	font-weight: 600;
`

const UserIcon = styled.div`
	height: 4.2rem;
	width: 4.2rem;
	font-size: 1.9rem;
	background: #f8f8f8;
	border: 4px solid #fff;
	border-radius: 50%;
	color: #e0e0e0;
	margin: 0px auto 15px;
	padding-top: 8px;
`

const MenuList = styled.ul`
	list-style: none;
	margin: 0px 0px 30px;
	padding: 0;
	& li {
		padding: 0;
    	margin: 0;
	}
	& li a {
		display: inline-block;
		padding: 14px 0;
		margin: 0;
		border-bottom: 1px solid #e8e8e8;
		width: 100%;
	}
	& li a:hover {
		cursor: pointer;
		background-color: #333;
		color: #fff !important;
	}
`