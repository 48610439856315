import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import { storeSaleLog } from '../services'
import { updateCustomerData } from '../../CustomerData/services'
import { handleError } from '../../../helpers'

export default function RegisterSaleLogModal({ visible, onClose, customer, prevInvoice, reload }) {
	const [loading, setLoading] = useState(false)
	const { document } = useSelector(state => state.auth.user)
	const [invoice, setInvoice] = useState('')
	const { register, formState: { errors }, handleSubmit, setValue } = useForm({
		defaultValues: {
			client_doc: customer.document,
			email: customer.email,
			birthday_day: customer.birthday ? customer.birthday.slice(-2) : '',
			birthday_month: customer.birthday ? customer.birthday.slice(0, 2) : '',
		}
	})

	const onSubmit = values => {
		setLoading(true)

		if(values.birthday_day !== '' && values.birthday_month !== '')
			values.birthday = `${values.birthday_month}-${values.birthday_day}`

		storeSaleLog({ 
			agent_doc: document, 
			prev_invoice_ref: prevInvoice.reference, 
			...values 
		})
			.then(() => {
				if(customer.email !== values.email && values.email && values.birthday){
					updateCustomerData({
						customer_id: customer.id,
						email: values.email.toLowerCase(),
						birthday: values.birthday,
						source: 'recompra_fiel',
						agent_document: document
					})
						.then(res => {
							message.success('Recompra registrada para validación.')
							onClose()
							reload()
						})
						.catch(error => handleError(error, setLoading))
					} else {
						message.success('Recompra registrada para validación.')
						onClose()
						reload()
					}
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Registrar Recompra</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Form.Group as={Col} className='mb-1'>
							<Form.Label>CC cliente que recompra</Form.Label>
							<Form.Control {...register('client_doc')} disabled />
						</Form.Group>
						<Form.Group as={Col} className='mb-1'>
							<Form.Label>Número de Factura <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								value={invoice}
								onChange={e => {
									let formatedValue = e.target.value.replace('-','').replace('/','').replace(' ','')
									setInvoice(formatedValue)
									setValue('invoice', formatedValue)
								}}
								placeholder='S00F1000'
							/>
							<Form.Control {...register('invoice', { required: true })} style={{ display:'none' }} />
							{ errors.invoice && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
					</Row>
					<Form.Text className='text-info mb-2'>Escriba un solo número de factura, sin guión, sin espacios e incluyendo el código de la tienda al inicio. Ejemplo: S00F1000</Form.Text>
					{ (!customer.email || !customer.birthday) && (
						<React.Fragment>
							<Row>
								<Form.Group as={Col} sm="7" className='mb-0'>
									<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
									<Form.Control 
										type="email"
										{...register('email', {})} 
										placeholder='Escriba aquí...'
									/>
								</Form.Group>
								<Form.Group as={Col} className='mb-0'>
									<Form.Label>Cumpleaños <small>(opcional)</small></Form.Label>
									<InputGroup>
										<Form.Control
											as="select"
											{...register('birthday_day', {})}
											style={{ maxWidth: '70px' }}
										>
											<option value="">:: Día ::</option>
											<option value="01">1</option>
											<option value="02">2</option>
											<option value="03">3</option>
											<option value="04">4</option>
											<option value="05">5</option>
											<option value="06">6</option>
											<option value="07">7</option>
											<option value="08">8</option>
											<option value="09">9</option>
											<option value="10">10</option>
											<option value="11">11</option>
											<option value="12">12</option>
											<option value="13">13</option>
											<option value="14">14</option>
											<option value="15">15</option>
											<option value="16">16</option>
											<option value="17">17</option>
											<option value="18">18</option>
											<option value="19">19</option>
											<option value="20">20</option>
											<option value="21">21</option>
											<option value="22">22</option>
											<option value="23">23</option>
											<option value="24">24</option>
											<option value="25">25</option>
											<option value="26">26</option>
											<option value="27">27</option>
											<option value="28">28</option>
											<option value="29">29</option>
											<option value="30">30</option>
											<option value="31">31</option>
										</Form.Control>
										<Form.Control
											as="select"
											{...register('birthday_month', { })}
										>
											<option value="">:: Mes ::</option>
											<option value="01">Enero</option>
											<option value="02">Febrero</option>
											<option value="03">Marzo</option>
											<option value="04">Abril</option>
											<option value="05">Mayo</option>
											<option value="06">Junio</option>
											<option value="07">Julio</option>
											<option value="08">Agosto</option>
											<option value="09">Septiembre</option>
											<option value="10">Octubre</option>
											<option value="11">Noviembre</option>
											<option value="12">Diciembre</option>
										</Form.Control>
									</InputGroup>
								</Form.Group>
							</Row>
							<Form.Text className='text-info mb-2'>Completa los datos del cliente para participar en el 	concurso de actualización de datos Spring.</Form.Text>
						</React.Fragment>
					)}
					<Form.Group>
						<Form.Label>Comentarios <small>(opcional)</small></Form.Label>
						<Form.Control 
							{...register('comments', { })}
							placeholder='Escriba aquí comentarios para usted'
							as="textarea"
							style={{ height: '50px' }}
							rows={2}
						/>
					</Form.Group>
					<Button type="submit" color='primary' className='mb-2' disabled={loading}>
						Enviar Recompra a Revisión{" "}
						{ loading && <Spinner animation='border' size="sm" /> }
					</Button>
					<Alert color='info'>
						Al enviar una recompra a revisión confirma:
						<ol className='mt-2'>
							<li>La venta fue resultado del seguimiento del asesor al cliente para gestionar su recompra</li>
							<li>La factura fue realizada al cliente: <strong>{customer.name.toUpperCase()} ({customer.document})</strong></li>
							<li>Es una compra fue realizada durante <strong>{moment().format('MMMM').toLocaleUpperCase()}</strong></li>
							<li>El cliente tiene una factura previa al mes en curso.</li>
							<li>No han pasado más de 15 días desde que el cliente realizó la recompra</li>
							<li>El cliente no tiene una nota crédito en los últimos 30 días.</li>
						</ol>
						En caso que la factura incumpla con los requisitos no será rechazada. En el menú principal ingrese a "Recompras Registradas" para conocer el estado de validación de sus recompras registradas.
					</Alert>
				</Form>
			</ModalBody>
		</Modal>
	)
}