import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Divider, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faDollarSign, faFileInvoiceDollar, faPhone } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import RegisterSaleLogModal from '../../SaleLogs/partials/RegisterSaleLogModal'
import RegisterCallLogModal from '../../CallLogs/partials/RegisterCallLogModal'
import CustomerInvoicesDrawer from '../../Customers/partials/CustomerInvoicesDrawer'
import Pagination from '../../../components/Pagination'

import { storeClickLog } from '../../ClickLogs/services'
import { handleError } from '../../../helpers'

export default function InvoicesTable({ invoices, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [invoicesDrawer, setInvoicesDrawer] = useState(null)
	const [registerSaleLog, setRegisterSaleLog] = useState(null)
	const [registerCallLog, setRegisterCallLog] = useState(null)
	
	const columns = [
		{
			title: 'Fecha Factura',
			width: '120px',
			dataIndex: 'sold_at',
			render: t => moment(t).format('DD-MM-YYYY')
		},
		{
			title: 'Referencia',
			dataIndex: 'reference',
			render: t => t.replace(/(\w| )(?=(\w| ){3})/g, '*')
		},
		{
			title: 'Cliente',
			dataIndex: 'customer',
			render: t => t.name.toUpperCase()
		},
		{
			title: 'Cédula',
			dataIndex: 'customer',
			render: t => t.document
		},
		// {
		// 	title: 'Valor Total',
		// 	width: '120px',
		// 	dataIndex: 'products',
		// 	render: t => {
		// 		let total = 0
		// 		t.map(p => total = total + parseInt(p.pivot.value))
		// 		return `$ ${currency(total)}`
		// 	}
		// },
		{
			title: 'Facturas Cliente',
			dataIndex: 'customer',
			render: t => `${t.invoices.length} facturas`
		},
		{
			title: 'Llamadas Registradas',
			dataIndex: 'customer',
			render: t => `${t.call_logs.length} llamadas`
		},
		{
			title: 'Clics Btn WA',
			dataIndex: 'click_logs_count',
			render: t => `${t} clics`
		},
		{
			title: 'Recompras Registradas',
			dataIndex: 'customer',
			render: t => `${t.sale_logs.length} recompras`
		},
		{
			title: 'Acciones',
			width: '210px',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Realizar Llamada">
						<Button 
							size='sm' 
							color={r.customer.is_callable ? 'primary' : 'dark'} 
							disabled={!r.customer.is_callable}
							outline 
							onClick={() => setRegisterCallLog(r)}
						>
							<FontAwesomeIcon icon={faPhone} />
						</Button>
					</Tooltip>
					<Divider type='vertical' />
					{ ((r.customer.mobile && r.customer.mobile.toString().length === 10) || (r.customer.phone && r.customer.phone.toString().length === 10)) && (
						<React.Fragment>
							<Tooltip title="Whatsapp">
								<Button 
									size='sm' 
									color={r.customer.is_callable ? 'primary' : 'dark'} 
									disabled={!r.customer.is_callable}
									outline 
									onClick={() => handleClickWhatsapp(r)}
								>
									<FontAwesomeIcon icon={faComments} />
								</Button>
							</Tooltip>
							<Divider type='vertical' />
						</React.Fragment>
					)}
					<Tooltip title="Registrar Venta">
						<Button 
							size='sm' 
							color="primary" 
							outline 
							className='px-2' 
							onClick={() => setRegisterSaleLog(r)}
						>
							<FontAwesomeIcon icon={faDollarSign} />
						</Button>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Ver Facturas">
						<Button 
							size='sm' 
							color="primary" 
							outline 
							className='px-2' 
							onClick={() => setInvoicesDrawer(r.customer.id)}
						>
							<FontAwesomeIcon icon={faFileInvoiceDollar} />
						</Button>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			{ r.products.map(product => (
				<p key={product.id} className='mb-2'>
					{product.description} ({product.pivot.quantity} UND)
				</p>
			)) }
		</React.Fragment>
	)

	const handleClickWhatsapp = r => {
		storeClickLog({
			user_id: currentUser.id,
			invoice_id: r.id,
			type: 'whatsapp'
		})
			.then(() => {
				reload()
				window.open(`https://wa.me/57${(r.customer.mobile && r.customer.mobile.toString().length === 10) ? r.customer.mobile : r.customer.phone}?text=`)
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={invoices}
				columns={columns}
				rowKey={r => r.reference}
				loading={!invoices}
				expandable={{ expandedRowRender }}
				pagination={false}
				size="small"
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ invoicesDrawer && (
				<CustomerInvoicesDrawer 
					visible
					onClose={() => setInvoicesDrawer(null)}
					customerId={invoicesDrawer}
				/>
			)}
			{ registerSaleLog && (
				<RegisterSaleLogModal 
					visible
					onClose={() => setRegisterSaleLog(null)}
					customer={registerSaleLog.customer}
					prevInvoice={registerSaleLog}
					reload={reload}
				/>
			)}
			{ registerCallLog && (
				<RegisterCallLogModal 
					visible
					onClose={() => setRegisterCallLog(null)}
					customer={registerCallLog.customer}
					invoiceId={registerCallLog.id}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}